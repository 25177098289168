import { handleActions } from "redux-actions";
import Immutable from "immutable";
import {
  SET_ALL_TASKS,
  SET_TASK_SELECTED,
  SET_FILTER_SELECTED,
  SET_FORM_SELECTED,
  SHOULD_RELOAD_TASKS,
  SET_ARCHIVE_RECENTLY,
  ARCHIVE_TASKS,
  SET_FORMS_FROM_API,
  CREATE_NEW_TASK,
  SIGN_TASK,
  REMOVE_TASK,
  SET_TASKS_STATUS,
  SET_TASK_PAGE,
  SET_TASK_COUNT,
  SET_TASKLOG_COUNT,
  SET_TASKLOG_PAGE,
  GET_LOG_GROUPS_TASKS,
  SET_FORMS_LOADED,
  SET_FORMS_LOADED_END,
  SET_FORMS_EMPTY,
  QUIT_ORDER_FORM,
  SET_TITLE,
  GET_PROMPT_CATEGORIES,
  SET_ACTIVE_RELOAD
} from "../constants/actions";
/*import { Actions } from "react-native-router-flux";*/
import { defaultCategories } from "../constants/AiPrompt"; 
import StorageService from "../services/storage";

const initialState = {
  tasks: [],
  taskSelected: {},
  formSelected: {},
  filter: "All",
  taskCreated: {},
  reload: false,
  archiveRecently: false,
  formList:[],
  lastUpdated: null,
  status: "idle",
  currentPage: 1,
  tasksCount: 0,
  pageSize: 10,
  currentLogPage:1,
  taskLogCount:0,
  logPageSize:5,
  taskLogTotal:null,
  formsLoaded: false,
  formsLoadedEnd: false,
  showTypesFilter: false,
  promptTitle: "",
  availableCategories: [],
  categories: [],
  prompts: [],
  default_prompt: null,
  activeReload: false,
};


export default handleActions(
  {
    [SET_FORMS_LOADED]: (state, action) => {
      return state.set("formsLoaded", action.payload) 
    },
    [SET_FORMS_LOADED_END]: (state, action) => {
      return state.set("formsLoadedEnd", action.payload) 
    },
    [SET_FORMS_EMPTY]: (state, action) => {
      return state.set("formList", []);
    },
    [SET_TASKLOG_PAGE]: (state, action) => {
      return state.set("currentLogPage", action.payload);
    },
    [SET_TASKLOG_COUNT]: (state,action) => {
      return state.set("taskLogCount", action.payload);
    },
    [SET_TASK_PAGE]: (state, action) => {
      return state.set("currentPage", action.payload);
    },
    [SET_TASK_COUNT]: (state,action) => {
      return state.set("tasksCount", action.payload);
    },
    [SET_TASKS_STATUS]: (state, action) => {
      return state.set("status", action.payload);
    },
    [SET_ALL_TASKS]: (state, action) => {
      //   StorageService.set("tasks", action.payload);
      const dateNow = new Date();
      
      return state.set("tasks", action.payload)
      .set("lastUpdated",dateNow)
      .set("status",'idle');
    },
    [SET_TASK_SELECTED]: (state, action) => {
      return state.set("taskSelected", action.payload);
    },
    [SET_FILTER_SELECTED]: (state, action) => {
      return state.set("filter", action.payload);
    },
    [SET_FORM_SELECTED]: (state, action) => {
      return state.set("formSelected", action.payload);
    },
    [SHOULD_RELOAD_TASKS]: (state, action) => {
      return state.set("reload", action.payload);
    },
    [SET_ARCHIVE_RECENTLY]: (state, action) => {
      return state.set("archiveRecently", action.payload);
    },
    [ARCHIVE_TASKS]: (state, action) => {
      return state;
    },
    [SET_FORMS_FROM_API]: (state, action) => {
      const formList = state.get("formList");
      let ob = action.payload.forms;
      let showTypesFilter = action.payload?.showTypesFilter ? action.payload?.showTypesFilter : state.get("showTypesFilter");

      ob.map((item) => {
        item.isSelected = false;
        return item;
      });
      if (formList.hasOwnProperty("size")) {
        return state.set("formList", [...ob]).set("showTypesFilter", showTypesFilter);
      } else {
        return state.set("formList", [...formList, ...ob]).set("showTypesFilter", showTypesFilter); 
      }
    },
    [CREATE_NEW_TASK]: (state, action) => {
      return state.set("taskCreated", action.payload)
      .set("taskSelected",action.payload);
    },
    [SIGN_TASK]: (state, action) => {
      return state.set("tasks", action.payload);
    },
    [REMOVE_TASK]: (state,action)=> {
      return state.set("tasks",action.payload);
    },
    [GET_LOG_GROUPS_TASKS]: (state,action)=> {
      console.log('task number => '+action.payload)
      return state.set("taskLogTotal",action.payload);
    },
    [QUIT_ORDER_FORM]: (state, action) => {
      const formList = state.get("formList");
      let formId = action.payload;
      let newForms = formList;
      if(formId){
        newForms = newForms.filter(form => form._id != formId);
      }
      
      return state.set("formList", [...newForms]);
      
    }, 
    [SET_TITLE]: (state,action)=> {
      return state.set("promptTitle", action.payload);
    },
    [GET_PROMPT_CATEGORIES]: (state, action)=> {
      const { response } = action.payload;
      var availableCategories = response.availableCategories ? response.availableCategories : response;
      var categories = response.categories ? response.categories : defaultCategories;
      var prompts = response.prompts;
      var default_prompt = response.default_prompt;

      return state.set("availableCategories", availableCategories).set("categories", categories).set("prompts", prompts).set("default_prompt", default_prompt);
    },
    [SET_ACTIVE_RELOAD]: (state, action) => {
      return state.set("activeReload", action.payload) 
    },
  },
  Immutable.fromJS(initialState)
);
